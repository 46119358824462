<template>
  <v-card flat>
    <v-card-title>
      <menu-title title="Customers">
        <v-btn
          outlined
          disabled
          v-if="false"
        >
          <span>Export</span>
        </v-btn>

        <v-btn
          outlined
          class="ml-2"
          @click="selectionEnabled = !selectionEnabled"
        >
          <span v-if="selectionEnabled">Stop Selection</span>
          <span v-else>Start Selection</span>
        </v-btn>
        <v-btn
          outlined
          class="ml-2"
          :disabled="selectedLicenses.length == 0"
          @click="downloadLicenses"
        >
          <span>Download</span>
        </v-btn>

        <v-btn
          class="ml-2"
          outlined
          @click="selectedCustomer=null;customerDialog=true"
        >
          <v-icon>mdi-plus</v-icon>
          <span>New Customer</span>
        </v-btn>
      </menu-title>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <v-list
        v-for="sub in allSubcustomers"
        :key="sub.customerid"
      >
        <customer-item
          v-model="selectedLicenses"
          :subcustomer="sub"
          :selectionEnabled="selectionEnabled"
        ></customer-item>
      </v-list>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        color="#b31616"
        v-if="selectedLicenses.length != 0"
        bottom
        right
        fab
        fixed
        outlined
        @click="downloadLicenses"
      >
        <v-icon color="#b31616d0">mdi-download</v-icon>
      </v-btn>
    </v-fab-transition>

    <customer-dialog
      v-if="customerDialog"
      v-model="customerDialog"
      :subcustomer="selectedCustomer"
      @done="selectedCustomer=null;customerDialog=false;"
    ></customer-dialog>

  </v-card>
</template>

<script>
import menuTitle from "../../components/helpers/menu_title.vue";
import customerItem from "../../components/user/customers_list_item.vue";
import customerDialog from "../../components/user/customer_dialog.vue";
import { mapGetters, mapActions } from "vuex";
import { saveAs } from "file-saver";
export default {
  name: "customers",
  components: {
    menuTitle,
    customerItem,
    customerDialog,
  },
  computed: {
    ...mapGetters({
      allSubcustomers: "allSubcustomers",
      customerlicensesByLicenseId: "customerlicensesByLicenseId",
    }),
  },
  data: () => ({
    selectedLicenses: [],
    selectedCustomer: null,
    selectionEnabled: false,
    customerDialog: false,
  }),
  methods: {
    ...mapActions({
      fetchSubcustomersAsync: "fetchSubcustomersAsync",
    }),
    downloadLicenses: function () {
      let promises = [];
      this.selectedLicenses.forEach((x) => {
        promises.push(
          new Promise((resolve, reject) => {
            this.$axios
              .get(`/signlicense?licenseid=${x}`)
              .then((response) => resolve(response))
              .catch((error) => reject(error));
          })
        );
      });
      Promise.all(promises)
        .then((responses) => {
          let download = [];
          responses.forEach((response) => {
            download.push({
              ...response.data,
            });
          });

          const data = JSON.stringify(download);
          const blob = new Blob([data], { type: "text/plain" });
          const e = document.createEvent("MouseEvents");
          let a = document.createElement("a");
          a.download = "licenses.json";
          a.href = window.URL.createObjectURL(blob);
          a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
          e.initEvent(
            "click",
            true,
            false,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          );
          a.dispatchEvent(e);
          console.log(download);
        })
        .catch((error) => {
          console.log("error");
          console.log(error);
        });
    },
  },
  async mounted() {
    await this.fetchSubcustomersAsync();
  },
};
</script>

<style>
</style>