<template>
  <v-card outlined>
    <v-card-title align="left">
      {{ subcustomer.name }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <!--
        <v-col
          cols="3"
          align="left"
        >
          <v-container>
            <label>Maintenance:&nbsp;</label>
            <label>{{ subcustomer.maintenance_active ? 'Active' : 'Inactive' }}</label>
            <v-icon :color="subcustomer.maintenance_active ? '#00ff00' : '#ff0000'">
              {{ subcustomer.maintenance_active ? 'mdi-check-bold' : 'mdi-exclamation-thick' }}
            </v-icon>
          </v-container>
        </v-col>
        -->
        <v-col cols="9" align="left">
          <v-container>
            <label>License QTY:&nbsp;</label>
            <label>{{ licenseQuantity }}</label>
          </v-container>
        </v-col>
        <v-col cols="3" align="right" class="pr-5">
          <v-container>
            <v-menu offset-y open-on-hover transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined v-on="on" v-bind="attrs">
                  Manage&nbsp;
                  <v-icon>mdi-arrow-down-drop-circle</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link @click="licenseRequestDialog = true">
                  <v-list-item-icon>
                    <v-icon>mdi-file-sign</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Request License</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="customerDialog = true">
                  <v-list-item-icon>
                    <v-icon>mdi-cog-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Edit Customer</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="deleteCustomerDialog = true">
                  <v-list-item-icon>
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Delete Customer</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-container>
        </v-col>
      </v-row>

      <v-container v-if="expanded">
        <v-row>
          <v-col cols="stretch">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <licenses-list
            :customerid="subcustomer.customerid"
            :selection-enabled="selectionEnabled"
          ></licenses-list>
        </v-row>
      </v-container>
      <v-row>
        <v-col cols="stretch" align="center">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                outlined
                large
                @click="expanded = !expanded"
              >
                <span v-if="expanded">Hide license information</span>
                <span v-else>Show license information</span>
              </v-btn>
            </template>
            <span v-if="expanded">Show less</span>
            <span v-else>Show more</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-text>

    <customer-dialog
      v-if="customerDialog"
      v-model="customerDialog"
      :subcustomer="subcustomer"
    ></customer-dialog>

    <accept-dialog
      v-model="deleteCustomerDialog"
      title="Delete Customer?"
      :text="
        'Are you sure you want to delete this customer: \'' +
        subcustomer.name +
        '\'?'
      "
      @accepted="deleteCustomer"
    ></accept-dialog>

    <license-request-dialog
      v-if="licenseRequestDialog"
      v-model="licenseRequestDialog"
      :subcustomer="subcustomer"
    ></license-request-dialog>
  </v-card>
</template>

<script>
import acceptDialog from "../helpers/generic_accept_dialog.vue";
import licenseRequestDialog from "../user/customer_license_request_dialog.vue";
import customerDialog from "./customer_dialog.vue";
import licensesList from "../licenses_list.vue";

import { mapGetters, mapActions } from "vuex";
export default {
  name: "customerItem",
  components: {
    customerDialog,
    acceptDialog,
    licenseRequestDialog,
    licensesList,
  },
  props: {
    subcustomer: {
      type: Object,
      required: true,
    },
    selectionEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    expanded: false,
    customerDialog: false,
    licenseRequestDialog: false,
    deleteCustomerDialog: false,
  }),
  computed: {
    ...mapGetters({
      templateById: "templateById",
      applicationById: "applicationById",
      customerlicensesBySubcustomerId: "customerlicensesBySubcustomerId",
      customerlicensesBySubcustomerIdActiveLicenses:
        "customerlicensesBySubcustomerIdActiveLicenses",
    }),
    licenseQuantity: function () {
      return this.customerlicensesBySubcustomerIdActiveLicenses(
        this.subcustomer.customerid
      ).length;
    },
  },
  methods: {
    ...mapActions({
      fetchCustomerlicensesAsync: "fetchCustomerlicensesAsync",
      fetchApplicationsAsync: "fetchApplicationsAsync",
      fetchTemplatesAsync: "fetchTemplatesAsync",
      delete: "deleteSubcustomer",
      showSnackbar: "showSnackbar",
    }),
    deleteCustomer: function () {
      console.log("deleting...");
      this.delete(this.subcustomer).then((error) => {
        if (error) {
          this.showSnackbar("Customer could not be deleted. Error: " + error);
        } else {
          this.showSnackbar("Customer has been deleted");
        }
      });
    },
  },
  async mounted() {
    await this.fetchCustomerlicensesAsync();
    await this.fetchApplicationsAsync();
    await this.fetchTemplatesAsync();
  },
};
</script>

<style scoped>
</style>